
import { defineComponent, reactive, ref, toRefs, toRaw } from "vue";
import { message } from "ant-design-vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import searchTitle from "../components/searchTitle.vue";
import SamplingModal from "../components/samplingModal.vue";
import VinDrawer from "../components/vinDrawer.vue";
import ConfirmModal from "../components/confirmModal.vue";

import useControllingChecked from "@/hooks/checking/spotChecking/checkingConfirm/useDealerVinView";
import {
  spotCheckSampledOptions,
  controllingSampledOptions,
  // checkStatusOptions,
} from "../components/selectOptions";
import {
  fetchVinDealer,
  fetchVinDealerBatch,
  fetchVinDealerToSipo,
  dealerVerifyReturnByOfferTypeId,
  dealerVerifyConfirmByOfferTypeId,
  dealerAddComments2,
  getCheckBeforeConfirm,
  getBatchConfirmResultListControllingReviewed,
} from "@/API/checking/spotChecking";
import downloadFile from "@/utils/payment/downloadFile";
import ReturnConfirmModal from "../components/returnModalConfirm.vue";
import { toThousandsAndTwoDecimal } from "@/utils/payment/numberFormat";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const isOpen = ref<boolean>(false);
    const {
      column,
      data,
      dataRes,
      currentData,
      tableSelection,
      query,
      summary,
      getTableData,
      total,
      pagination,
      getProgramList,
      unCheckedProgramList,
      ToDoListLen,
      getTodoListLength,
      curProgram,
      programIndex,
      isInProgram,
      isConfirmedSipo,
      isControllingChecked,
      statusList,
      getSiOfferList,
      siOfferList,
    } = useControllingChecked();
    const init = () => {
      getSiOfferList();
      getProgramList();
      getTodoListLength();
      getTableData();
    };
    init();
    const search = () => {
      tableSelection.selectedRowKeys = [];
      getTableData();
    };
    const reset = () => {
      query.checkStatus = "-1";
      query.queryDealerTrade = "";
      query.controllingSampled = "-1";
      query.dealerCode = "";
      query.dealerName = "";
      query.model = "";
      query.rejectHistory = false;
      query.spotCheckSampled = "-1";
      query.typeClass = "";
      query.vin = "";
      query.si = [];
      search();
    };
    const initPageData = () => {
      getTodoListLength();
      if (
        programIndex.value !== undefined &&
        unCheckedProgramList.value.length > 0
      ) {
        curProgram.value = unCheckedProgramList.value[programIndex.value];
      }
      query.programCode = curProgram.value?.programCode;
      query.offerType = curProgram.value?.offerType;
      search();
    };
    const isTable = ref(false);
    //#region action
    const isNoData = () => {
      let flag = true;
      if (
        tableSelection.selectedRowKeys.length === 0 &&
        isTable.value !== true
      ) {
        message.warning("Please select the data first");
        flag = false;
      }
      return flag;
    };
    const samplingModalVisible = ref(false);
    const handleSamling = () => {
      samplingModalVisible.value = true;
    };
    const handleSamplingChange = (val: any) => {
      message.success("Sampling Successfully");
      pagination.pageNumber = 1;
      pagination.pageSize = 10;
      search();
      // if (val.spotCheckVinRespVOList.length) {
      //   dataRes.value = val;
      // }
    };
    const vinDrawerVisible = ref(false);
    const vinDrawTitle = ref();
    const time = ref(new Date().toString());
    const curVinId = ref();
    const handleVinDrawerShow = (record: any) => {
      curVinId.value = record.id;
      time.value = new Date().toString();
      vinDrawerVisible.value = true;
      vinDrawTitle.value = record.vin;
    };
    const handleNext = () => {
      if (!isInProgram.value) {
        programIndex.value = 0;
        isInProgram.value = true;
      } else if (programIndex.value === unCheckedProgramList.value.length - 1) {
        programIndex.value = 0;
      } else {
        programIndex.value++;
      }
      if (
        programIndex.value !== undefined &&
        unCheckedProgramList.value.length > 0
      ) {
        curProgram.value = unCheckedProgramList.value[programIndex.value];
      }
      query.programCode = curProgram.value?.programCode;
      query.offerType = curProgram.value?.offerType;
      search();
    };
    const returnModalVisible = ref(false);
    const returnCommentModalVisible = ref(false);

    const curRowId = ref("");
    const handleReturn = async (disabled = false, id?: string) => {
      if (!disabled) {
        const result = await dealerVerifyReturnByOfferTypeId(
          curProgram.value.offerTypeId
        );
        if (!result.data) {
          // message.warning(result.message);
        } else {
          returnModalVisible.value = true;
        }
        // curRowId.value = id ? id : "";
      }
      // returnModalVisible.value = true;
    };
    const returnVisible = ref(false);
    const pageComments = ref("");
    const nowtableData = ref<any>({
      data: {} as any,
      tableStatus: {} as any,
    });
    const handleCloseReturnVisible = () => {
      returnVisible.value = false;
      pageComments.value = "";
    };
    const handleReturnConfirm = async (comments: string) => {
      if (comments.length === 0) {
        message.error("Reason is required");
        return;
      }
      await dealerAddComments2(
        comments,
        currentData.value.map((item: any) => item.packageCodeId)
      );
      const ids = data.value.map((item: any) => item.id);
      const params = {
        comments: "",
        id: "",
        ids,
        offerType: query.offerType,
        programCode: query.programCode,
        status: "2",
      };

      await fetchVinDealerBatch(params);
      message.success("Return Successfully");
      initPageData();
      returnCommentModalVisible.value = false;
    };
    const handleRowConfirm = async (row: any, disabled: boolean) => {
      if (disabled) {
        return false;
      }
      const result = await dealerVerifyConfirmByOfferTypeId(
        curProgram.value.offerTypeId
      );
      if (!result.data) {
        message.warning(result.message);
        return;
      }
      const params = {
        comments: "",
        id: "",
        ids: [row.id],
        offerType: query.offerType,
        programCode: query.programCode,
        status: "1",
      };
      fetchVinDealer(params).then(() => {
        message.success("Confirm Successfully");
        initPageData();
      });
    };
    const handleConfirm = async (isAddCom: any) => {
      if (pageComments.value.length === 0 && isAddCom) {
        message.error("Reason is required");
      } else {
        if (isAddCom) {
          dealerAddComments2(
            pageComments.value,
            isTable.value
              ? [nowtableData.value.data.packageCodeId]
              : tableSelection.selectedRowKeys
          );
        }
        if (isTable.value) {
          handleRowConfirm(
            nowtableData.value.data,
            nowtableData.value.tableStatus
          );
        } else {
          const result = await dealerVerifyConfirmByOfferTypeId(
            curProgram.value.offerTypeId
          );
          if (!result.data) {
            //message.warning(result.message);
            return;
          }
          if (isNoData()) {
            // const ids = data.value.map((item: any) => item.id);
            const ids = tableSelection.selectedRowKeys;
            const params = {
              comments: "",
              id: "",
              ids,
              offerType: query.offerType,
              programCode: query.programCode,
              status: "1",
            };
            fetchVinDealerBatch(params).then(() => {
              message.success("Confirm Successfully");
              initPageData();
            });
          }
        }
        handleCloseReturnVisible();
      }
    };
    const handleConfirmToSipo = () => {
      const programCode = query.programCode;
      const type = query.offerType;
      const msg = `Program ${programCode} & Offer Type ${type} can not be confirmed without controlling confirmed`;
      fetchVinDealerToSipo(programCode, type, query.packageCode)
        .then((res) => {
          console.log(res);
          if (res.data === 1) {
            message.success("Confirm Successfully");
            initPageData();
          } else if (res.data === 0) {
            message.error(msg);
            initPageData();
          }
        })
        .catch(() => {
          message.error(msg);
        });
    };
    // 只用在了点击每行的 confirm 可以优化 逻辑非常混乱！tableStatus 有点乱用了
    const isConfirm = (istable: any, tableData: any, tableStatus: any) => {
      if (tableStatus) {
        return;
      }
      isTable.value = istable;
      nowtableData.value.data = tableData;
      nowtableData.value.tableStatus = tableStatus;
      console.log(714, tableSelection.selectedRowKeys);
      if (tableSelection.selectedRowKeys.length === 0 && istable !== true) {
        message.warning("Please select the data first.");
      } else {
        const dealerIdArr = ref<any>([]);
        currentData.value.forEach((i: any, ind: any) => {
          dealerIdArr.value.push(i.packageCodeId);
        });

        const params = ref({
          ids: [] as any,
        });
        if (istable) {
          params.value.ids = [tableData.packageCodeId];
        } else {
          params.value.ids = [...tableSelection.selectedRowKeys];
        }
        getCheckBeforeConfirm(params.value).then((res) => {
          console.log(320, res);
          if (res) {
            returnVisible.value = true;
          } else {
            if (istable) {
              handleRowConfirm(tableData, tableStatus);
            } else {
              handleConfirm(false);
            }
          }
          //
        });
      }
    };
    const handleExport = () => {
      const _query: any = { ...toRaw(query) };
      _query.rejectHistory = _query.rejectHistory ? "1" : "-1";
      if (_query.checkStatus.length === 0) {
        _query.checkStatus = statusList.value.map((item: any) => item.name);
      }
      const params = {
        url: `/claimapi/SpotChecking/toExcelDealer`,
        method: "post",
        params: _query,
      };
      downloadFile(params);
    };
    //#endregion

    // cr-17371
    const confirmVisible = ref(false);

    // confirm 前的校验
    const checkBeforeConfirm = () => {
      const params = {
        ids: [query.packageCodeId],
      };
      return getCheckBeforeConfirm(params);
    };

    // confirn modal 里要展示的数据
    const confirmModalData = ref<any>([]);
    // 获取数据接口
    const getResultListControllingReviewed = () => {
      const params = {
        ids: tableSelection.selectedRowKeys,
      };
      getBatchConfirmResultListControllingReviewed(params).then((res: any) => {
        confirmModalData.value = res;
        confirmVisible.value = true;
      });
    };

    // Batch Submit 点击事件
    const handleBatchConfirmClick = async () => {
      if (tableSelection.selectedRowKeys.length === 0) {
        message.warning("Please select the data first");
        return;
      }
      // 校验
      const checkBeforeConfirmResult = await checkBeforeConfirm();
      if (checkBeforeConfirmResult) {
        // 如果为 true， 需要展示 returnVisible.value ?
        returnVisible.value = true;
      } else {
        // false 获取表格数据 并展示
        getResultListControllingReviewed();
      }
    };

    const handleConfirmReturnVisible = async () => {
      if (pageComments.value.length === 0) {
        message.error("Reason is required");
        return;
      }
      dealerAddComments2(
        pageComments.value,
        isTable.value
          ? [nowtableData.value.data.packageCodeId]
          : tableSelection.selectedRowKeys
      );
      if (isTable.value) {
        handleRowConfirm(
          nowtableData.value.data,
          nowtableData.value.tableStatus
        );
      } else {
        getResultListControllingReviewed();
      }
    };

    const confirmCancel = () => {
      confirmModalData.value = [];
    };

    // 弹框里的 comfirm 事件， 有个新接口 怎么调用有待考量
    const confirmSuccess = async () => {
      const result = await dealerVerifyConfirmByOfferTypeId(
        curProgram.value.offerTypeId
      );
      if (!result.data) {
        //message.warning(result.message);
        return;
      }
      fetchVinDealerBatch({
        comments: "",
        id: "",
        ids: tableSelection.selectedRowKeys,
        offerType: query.offerType,
        programCode: query.programCode,
        status: "1",
      }).then(() => {
        message.success("Confirm Successfully");
        initPageData();
      });
    };

    return {
      nowtableData,
      isTable,
      isConfirm,
      returnVisible,
      pageComments,
      handleCloseReturnVisible,
      column,
      data,
      currentData,
      tableSelection,
      query,
      ...toRefs(query),
      summary,
      search,
      reset,
      isOpen,
      total,
      ...toRefs(pagination),
      isPagination: true,
      spotCheckSampledOptions,
      controllingSampledOptions,
      statusList,
      siOfferList,
      handleSamling,
      handleSamplingChange,
      samplingModalVisible,
      handleVinDrawerShow,
      vinDrawerVisible,
      vinDrawTitle,
      curVinId,
      unCheckedProgramList,
      ToDoListLen,
      handleNext,
      programIndex,
      curProgram,
      isInProgram,
      handleConfirm,
      handleReturn,
      handleConfirmToSipo,
      handleRowConfirm,
      isConfirmedSipo,
      isControllingChecked,
      handleExport,
      returnModalVisible,
      returnCommentModalVisible,
      handleReturnConfirm,
      toThousandsAndTwoDecimal,
      // returnCommentModalVisible,
      // handleReturnCommentShow,
      time,
      handleBatchConfirmClick,
      confirmModalData,
      confirmVisible,
      confirmSuccess,
      confirmCancel,
      handleConfirmReturnVisible,
    };
  },
  components: {
    searchTitle,
    TableWrapper,
    SamplingModal,
    VinDrawer,
    ReturnConfirmModal,
    ConfirmModal,
  },
});
